import React, { useEffect, useState } from "react";
import FormInput from "../FormInput/FormInput";
import PhoneInput from 'react-phone-number-input';
import Modal from "../../modal/Modal";
import 'react-phone-number-input/style.css';
import './ReservationForm.css';
import Cookies from "js-cookie";
import PaymentMethodsModal from "../../paymentMethods/PaymentMethodsModal";
import { InputSwitch } from "primereact/inputswitch";

interface ReservationFormProps {
  name: string;
  setName: (name: string) => void;
  carPlate: string;
  setCarPlate: (carPlate: string) => void;
  mobileNumber: string;
  setMobileNumber: (mobileNumber: string) => void;
  email: string;
  setEmail: (email: string) => void;
  agreeWithRules: boolean;
  setAgreeWithRules: (agreeWithRules: boolean) => void;
  parkingDate: any;
  pricePerDay: number;
  totalPrice: number;
  showModal: boolean;
  toggleModal: () => void;
  emptySpot: string;
  goBack: () => void;
}

const ReservationForm = ({
  name,
  setName,
  carPlate,
  setCarPlate,
  mobileNumber,
  setMobileNumber,
  email,
  setEmail,
  agreeWithRules,
  setAgreeWithRules,
  parkingDate,
  showModal,
  toggleModal,
  totalPrice,
  emptySpot,
  goBack,
  pricePerDay
}: ReservationFormProps) => {
  const [showPaymentModal, setShowPaymentModal] = useState<boolean>(false);
  const [isInvoiceChecked, setIsInvoiceChecked] = useState<boolean>(false);
  const [companyName, setCompanyName] = useState<string>("");
  const [companyCode, setCompanyCode] = useState<string>("");
  const [taxesCode, setTaxesCode] = useState<string>("");
  const [companyAddress, setCompanyAddress] = useState<string>("");
  const [showAgreeWithRulesError, setShowAgreeWithRulesError] = useState<boolean>(false);


  useEffect(() => {
    const storedName = Cookies.get('name');
    const storedCarPlate = Cookies.get('carPlate');
    const storedMobileNumber = Cookies.get('mobileNumber');
    const storedEmail = Cookies.get('email');
    const storedAgreeWithRules = Cookies.get('agreeWithRules') === 'true';

    if (storedName) setName(storedName);
    if (storedCarPlate) setCarPlate(storedCarPlate);
    if (storedMobileNumber) setMobileNumber(storedMobileNumber);
    if (storedEmail) setEmail(storedEmail);
    setAgreeWithRules(storedAgreeWithRules);
  }, [setName, setCarPlate, setMobileNumber, setEmail, setAgreeWithRules]);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    if (!agreeWithRules) {
      setShowAgreeWithRulesError(true);
      return;
    }

    setShowAgreeWithRulesError(false);

    Cookies.set('name', name);
    Cookies.set('carPlate', carPlate);
    Cookies.set('mobileNumber', mobileNumber);
    Cookies.set('email', email);

    if (isInvoiceChecked) {
      Cookies.set('companyName', companyName);
      Cookies.set('companyCode', companyCode);
      Cookies.set('taxesCode', taxesCode);
      Cookies.set('companyAddress', companyAddress);
    }

    setShowPaymentModal(true);
  };

  const namePattern = /^[A-Za-z0-9]{3,16}$/;
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const carPlatePattern = /^[a-zA-Z0-9]{1,8}$/;

  return (
    <div id="reservationForm">
      <div id="formContainer">
        <form onSubmit={handleSubmit} id="orderForm">
          <FormInput
            className="textInput"
            label="Vardas:"
            placeholder="Vardas"
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            pattern={namePattern.source}
            title="Name should be 3-16 characters and shouldn't include any special character!"
            required
          />
          <FormInput
            label="Automobilio valstybinis numeris:"
            className="textInput"
            placeholder="AAA000"
            type="text"
            value={carPlate}
            onChange={(e) => setCarPlate(e.target.value)}
            pattern={carPlatePattern.source}
            title="Input shouldn't include any special character!"
            required
          />
          <PhoneInput
            style={{ marginBottom: "1rem" }}
            international
            defaultCountry="LT"
            placeholder="Enter phone number"
            value={mobileNumber}
            onChange={(value: string | undefined) => {
              if (value && /^\+[0-9]{7,15}$/.test(value)) {
                setMobileNumber(value);
              }
            }}
            required
          />

          <FormInput
            label="El. pašto adresas:"
            className="textInput"
            placeholder="info@parknfly.lt"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            pattern={emailPattern.source}
            title="It should be a valid email address!"
            required
          />
          <div className="flexRow between">
            <label className="agreeWithRulesLabel">
              <div>Sutinku su</div>
              <div className="linkButton" onClick={toggleModal}>taisyklėmis</div>
              {showModal && <Modal showModal={showModal} onClose={toggleModal} />}
            </label>
            <div>
              {showAgreeWithRulesError && (
                <i className="pi pi-exclamation-triangle" style={{ color: "red", marginLeft: "0.5rem" }} />
              )}
              <InputSwitch
                required
                checked={agreeWithRules}
                onChange={(e) => setAgreeWithRules(e.value)}
                style={{ width: "3rem", height: "1.5rem" }}
              />
            </div>

          </div>
          <div className="flexRow between">
            <label className="agreeWithRulesLabel">
              <div>Reikalinga sąskaita faktūra</div>
            </label>
            <InputSwitch
              checked={isInvoiceChecked}
              onChange={(e) => setIsInvoiceChecked(e.value)}
              style={{ width: "3rem", height: "1.5rem" }}
            />
          </div>
          {isInvoiceChecked && (
            <>
              <FormInput
                label="Įmonės pavadinimas:"
                className="textInput"
                placeholder="Įmonės pavadinimas"
                type="text"
                value={companyName}
                onChange={(e) => setCompanyName(e.target.value)}
                required
              />
              <FormInput
                label="Įmonės kodas:"
                className="textInput"
                placeholder="Įmonės kodas"
                type="text"
                value={companyCode}
                onChange={(e) => setCompanyCode(e.target.value)}
                required
              />
              <FormInput
                label="Įmonės PVM kodas:"
                className="textInput"
                placeholder="Įmonės PVM kodas"
                type="text"
                value={taxesCode}
                onChange={(e) => setTaxesCode(e.target.value)}
                required
              />
              <FormInput
                label="Įmonės adresas:"
                className="textInput"
                placeholder="Įmonės adresas"
                type="text"
                value={companyAddress}
                onChange={(e) => setCompanyAddress(e.target.value)}
                required
              />
            </>
          )}
          <div className="flexRow between">
            <button type="submit">Rezervuoti</button>
            <button onClick={goBack}>Grįžti</button>
          </div>
        </form>
      </div>
      {showPaymentModal && (
        <PaymentMethodsModal
          onClose={() => setShowPaymentModal(false)}
          formDataProp={{
            name,
            carPlate,
            mobileNumber,
            email,
            agreeWithRules,
            parkingDate,
            totalPrice,
            pricePerDay,
            isInvoiceChecked,
            companyName: isInvoiceChecked ? companyName : null,
            companyCode: isInvoiceChecked ? companyCode : null,
            taxesCode: isInvoiceChecked ? taxesCode : null,
            companyAddress: isInvoiceChecked ? companyAddress : null,
          }}
          emptySpot={emptySpot}
        />
      )}
    </div>
  );
};

export default ReservationForm;
