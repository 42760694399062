import React, { useState, useEffect, useRef } from "react";
import { DateRange } from 'react-date-range';
import { format, differenceInCalendarDays } from 'date-fns';
import { useNavigate } from "react-router-dom";
import lt from 'date-fns/locale/lt';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { useCheckAvailability } from "../../hooks/useSpots";
const CheckOccupancy = () => {
  const [openDate, setOpenDate] = useState<boolean>(false);
  
  const { mutate: checkAvailability } = useCheckAvailability(); 

  const [parkingDate, setParkingDate] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection'
    } as any
  ]);
  const [isSpotFree, setIsSpotFree] = useState(true);
  const [selectedDays, setIsSelectedMoreThan1Days] = useState(true);
  const navigate = useNavigate();
  const dateRangeRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const handleOutsideClick = (event: MouseEvent) => {
    if (dateRangeRef.current && !dateRangeRef.current.contains(event.target as Node)) {
      setOpenDate(false);
    }
  };

  const handleDateChange = (item: any) => {
    const { startDate, endDate } = item.selection;
    setParkingDate([item.selection]);

    if (startDate && endDate && differenceInCalendarDays(endDate, startDate) > 0) {
      setOpenDate(false); 
    }
  };

  const onSubmit = async () => {
    const selectedStartDate = parkingDate[0].startDate;
    const selectedEndDate = parkingDate[0].endDate;
    const diffInDays = differenceInCalendarDays(selectedEndDate, selectedStartDate);

    if (diffInDays < 1) {
      setIsSelectedMoreThan1Days(false);
      return;
    }

    setIsSelectedMoreThan1Days(true);

    checkAvailability({ startDate: selectedStartDate, endDate: selectedEndDate }, {
      onSuccess: (result) => {
        if (result.success) {
          setIsSpotFree(true);
          navigate("/reservationPage", { state: { parkingDate, emptySpot: result.spotId } });
          sessionStorage.setItem("startDate", JSON.stringify(selectedStartDate));
          sessionStorage.setItem("endDate", JSON.stringify(selectedEndDate));
        } else {
          setIsSpotFree(false);
        }
      },
      onError: (error) => {
        console.error("Error checking availability:", error);
        setIsSpotFree(false);
      }
    });
  };

  return (
    <div id="reservation">
      <div id="reservationBox">
        <div className="dateRangeContainer">
          <div className="flexRow">
            <div className="priceBox">
              <div>Iki 5 parų</div>
              <div>
                <div className="dateBoxTitle">5€/para</div>
              </div>
            </div>
            <div className="priceBox">
              <div>6 paros ir ilgiau</div>
              <div className="dateBoxTitle">4.5€/para</div>
            </div>
          </div>
          <div ref={dateRangeRef}>
            <button
              onClick={() => setOpenDate(!openDate)}
              className="headerDateInputText"
            >
              Pasirinkti automobilio stovėjimo laikotarpį
            </button>

            <div className="dateRangeBox">
              {openDate && (
                <DateRange
                  editableDateInputs={true}
                  onChange={handleDateChange}
                  moveRangeOnFirstSelection={false}
                  ranges={parkingDate}
                  className="dateInput"
                  locale={lt}
                  minDate={new Date()}
                />
              )}
            </div>
          </div>

          <div className="dateBoxContainer">
            <div className="dateBox">
              <div className="dateBoxTitle">Atvykimo data</div>
              <div>{`${format(parkingDate[0].startDate, "yyyy-MM-dd")}`}</div>
            </div>

            <div className="dateBox">
              <div className="dateBoxTitle">Išvykimo data</div>
              <div>{`${format(parkingDate[0].endDate, "yyyy-MM-dd")}`}</div>
            </div>
          </div>

          <button type="submit" onClick={onSubmit} className="checkButton">
            Tikrinti užimtumą
          </button>

        </div>
        {!isSpotFree && <div className="noSpotsError">Apgailestaujame, pasirinktomis dienomis laisvų vietų nėra</div>}
        {!selectedDays && <div className="noSpotsError">Norint atlikti rezervaciją reikia pasirinkti bent 2 dienas</div>}
      </div>
    </div>
  );
};

export default CheckOccupancy;
