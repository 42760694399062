import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { format, differenceInCalendarDays } from "date-fns";
import ReservationForm from "../../components/Forms/ReservationForm/ReservationForm";
import "./ReservationPage.css";

const ReservationPage: React.FC = () => {
  const location = useLocation();
  const parkingDate = location.state && location.state.parkingDate;
  const emptySpot = location.state && location.state.emptySpot;
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [carPlate, setCarPlate] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [email, setEmail] = useState("");
  const [agreeWithRules, setAgreeWithRules] = useState(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [paymentHtml, setPaymentHtml] = useState<string>('');
  const goBack = () => {
    navigate("/");
  };

  const pricePerDay = differenceInCalendarDays(new Date(parkingDate[0].endDate), new Date(parkingDate[0].startDate)) >= 5 ? 4.5 : 5 

  const calculatePrice = (startDate: Date, endDate: Date): number => {
    const daysBooked = differenceInCalendarDays(endDate, startDate);
    let price;
  
    if (daysBooked >= 5) {
      price = (daysBooked + 1) * 4.5;
    } else {
      price = (daysBooked + 1) * 5;
    }
  
    return Math.round(price * 100) / 100;
  };
  const totalPrice = calculatePrice(
    new Date(parkingDate[0].startDate),
    new Date(parkingDate[0].endDate)
  );

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  return (
    <div id="reservationPage">
      <div id="reservationContainer">
        <div>
          <h2>Rezervacijos informacija:</h2>
          <p>Jūsų pasirinktu laikotarpiu laisvų vietų aikštelėje yra</p>
          <div id="datesBox">
            <div>
              <h3>Atvykimo data:</h3>
              <div>{parkingDate && format(parkingDate[0].startDate, "yyyy-MM-dd")}</div>
              <div>nuo 00:00</div>
            </div>
            <div>
              <h3>Išvykimo data:</h3>
              <div>{parkingDate && format(parkingDate[0].endDate, "yyyy-MM-dd")}</div>
              <div>iki 23:59</div>
            </div>
          </div>
          <div>
            <button onClick={goBack}>Keisti datas</button>
          </div>
          <div>
            <h4 id="price">
              {parkingDate &&
                "Suma: " +
                totalPrice}
              €
            </h4>
          </div>
        </div>
        <div>
          <ReservationForm
            name={name}
            setName={setName}
            carPlate={carPlate}
            setCarPlate={setCarPlate}
            mobileNumber={mobileNumber}
            setMobileNumber={setMobileNumber}
            email={email}
            setEmail={setEmail}
            agreeWithRules={agreeWithRules}
            setAgreeWithRules={setAgreeWithRules}
            pricePerDay={pricePerDay}
            parkingDate={parkingDate}
            showModal={showModal}
            toggleModal={toggleModal}
            totalPrice={totalPrice}
            emptySpot={emptySpot}
            goBack={goBack}
          />
        </div>
      </div>
      {paymentHtml && (
        <div dangerouslySetInnerHTML={{ __html: paymentHtml }} />
      )}
    </div>
  );
};

export default ReservationPage;
